import React from 'react';
import { ImCross} from 'react-icons/im';
import classes from './ServiceCard.module.css';

const ServiceCard = ({currCard, onChangeCard}) => {
  if (currCard === 'Ingrown Toenails') {
    return (
      <div className={classes.ServiceCard}>
        <div className={classes.card}>
          <div className={classes.heading}>
            <h2>Ingrown Toenails</h2>
            <ImCross onClick={() => onChangeCard(null)} />
          </div>
          <div className={classes.cardContent}>
            <p>Ingrown toenails are common and can be very painful,  it is when the nail grows into the skin. The condition can become chronic, with severe pain and infection.</p>
            <p>Reasons for Ingrown toenails can be due to:</p>
            <ul>
              <li>Trauma such as knocking your toe or dropping something on your toe or stubbing of the toe</li>
              <li>Repetitive trauma such as playing sport or ill-fitting shoes</li>
              <li>Cutting your toenails incorrectly or cutting it too short</li>
              <li>A hereditary problem, which may mean that your nails are abnormally incurved</li>
            </ul>
            <p>If conservative care is not successful,  then you may be a candidate for a more permanent solution like a surgical treatment. This procedure is called Partial Nail Avulsion (PNA) it is performed under local anaesthesia and the success rate is very high with good outcomes. The technique involves removing only the smallest offending section of nail, is removed, once healed, a change may not even be noticed. We also offer Total Nail Avulsion (TNA) this is when we remove the whole nail altogether.</p>
          </div>
        </div>
      </div>
    );
  } else if (currCard === 'Heel and Arch Pain') {
    return (
      <div className={classes.ServiceCard}>
        <div className={classes.card}>
          <div className={classes.heading}>
            <h2>Heel and Arch Pain</h2>
            <ImCross onClick={() => onChangeCard(null)} />
          </div>
          <div className={classes.cardContent}>
            <p>There are many causes of heel pain including plantar fasciitis, stress fracture, Achilles tendinopathy, Haglunds’s deformity, fat pad atrophy, inflammatory /arthritic disorders, nerve entrapment, bursitis and gout. However, Plantar Fasciitis is the most common condition that we treat. Pain may be located in the bottom of the heel or in the arch. We will diagnose your problem and come up with a treatment plan.</p>
          </div>
        </div>
      </div>
    );
  } else if (currCard === 'Verrucae') {
    return (
      <div className={classes.ServiceCard}>
        <div className={classes.card}>
          <div className={classes.heading}>
            <h2>Verrucae</h2>
            <ImCross onClick={() => onChangeCard(null)} />
          </div>
          <p>Verrucae or warts are caused by the Human Papilloma Virus and are one of the most common viral infections of the skin.  Verrucae can occur anywhere on the foot and are usually well circumscribed and have a cauliflower appearance.  Red or black dots within the lesion are also normal and represent vascular and or nerve involvement.  It is common for thick calluses to surround or overly and cover the lesion especially on weight-bearing areas. The virus that causes verrucae can be transmitted through barefoot activity around public places like gymnasiums, swimming pools and showers.</p>
        </div>
      </div>
    )
  } else if (currCard === 'Corns and Calluses') {
    return (
      <div className={classes.ServiceCard}>
        <div className={classes.card}>
          <div className={classes.heading}>
            <h2>Corns and Calluses</h2>
            <ImCross onClick={() => onChangeCard(null)} />
          </div>
          <p>Callus is formed when the foot becomes out of balance and extra friction falls on particular areas of the foot. When this happens, the body may respond to the pressure by producing thickening in the surface layer of the skin, this is your body’s way of protecting itself.  If the cause of pressure is not relieved, calluses become painful. If pressure becomes concentrated in a small area, a hard corn may develop. Corn usually forms a core or nucleus in the middle, this is often relieved by enucleating the core. Sometimes the pressure of the corn or callus may produce inflammation that can result in acute pain, swelling and redness.</p>
        </div>
      </div>
    )
  } else if (currCard === 'Diabetes') {
    return (
      <div className={classes.ServiceCard}>
        <div className={classes.card}>
          <div className={classes.heading}>
            <h2>Diabetes</h2>
            <ImCross onClick={() => onChangeCard(null)} />
          </div>
          <p>Every 20 seconds a limb is lost to diabetes. When you have diabetes you are more at risk of having foot problems and complications. This typically happens due to nerve damage, or loss of feeling also known as neuropathy, in the feet and toes. When you have nerve damage in your feet, over time you lose the ability to feel pain. When this happens an injury, or a small cut or wound on your foot or toes can go unnoticed. These small injuries can quickly turn into larger and more complicated problems like foot ulcers. This is where we as podiatrists can help by advising you on how to take care of your feet.</p>
        </div>
      </div>
    );
  } else if (currCard === 'Footwear') {
    return (
      <div className={classes.ServiceCard}>
        <div className={classes.card}>
          <div className={classes.heading}>
            <h2>Footwear</h2>
            <ImCross onClick={() => onChangeCard(null)} />
          </div>
          <p>Your shoes can tell a lot about you. If shoes are fitted incorrectly this can place increased pressure on joints of the foot, resulting in ingrown- toenails, blistering, corns or callus. Incorrect fitting shoes can also make certain muscles work harder than necessary, which can either lead to injury, or slow the rate of recovery of an injury. In saying that, wearing good footwear that fits well and supports your feet can have a positive impact on healing. We have a number of recommended shoes that we can help you choose from.</p>
        </div>
      </div>
    );
  }
}

export default ServiceCard;