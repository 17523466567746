import React, {useState} from "react";
import {Link} from "gatsby";
import { StaticImage } from 'gatsby-plugin-image';
import classes from "./navbar.module.css";
import {menuItems} from "./menuItems";

import {IoIosMenu, IoIosClose} from "react-icons/io";

const Navbar = ({isHome}) => {
  const [navLinksOpen, setNavLinksOpen] = useState(false);

  function toggleNavLinks() {
    setNavLinksOpen(!navLinksOpen);
  }

  let navLinks = menuItems.map((item, index)=> {
    return <Link to={item.url} key={index} className={classes.navLink}>{item.title}</Link>
  });

  return (
    <div className={classes.navbarContainer}>
      <nav className={classes.navbarSmall}>
        <div className={classes.navbarClosed}>
          <Link to="/" className={classes.logo}>
            <StaticImage
              src='../../images/navbar/Logo_Small.png'
              alt='Footprint Podiatry Logo'
              placeholder='blurred'
              layout='fixed'
              height={36}
            />
          </Link>

          <div className={classes.menuToggle}>
            {navLinksOpen ? <IoIosClose onClick={toggleNavLinks} className={classes.icon} /> : <IoIosMenu onClick={toggleNavLinks} className={classes.icon} />}
          </div>

        </div>

        {navLinksOpen ? (
          <div className={classes.navLinks}>
            {navLinks}
          </div>
        ) : (
          null
        )}

      </nav>

      <nav className={classes.navbarLarge}>
        <div className={classes.navLinks}>
          {navLinks}
        </div>

        {!isHome &&
          <Link to="/" className={classes.logo}>
            <StaticImage
              alt='Footprint Podiatry Logo'
              src='../../images/navbar/Logo.png'
              layout='fixed'
              placeholder='blurred'
              height={48}
            />
          </Link>
        }
      </nav>
    </div>
  );
}

export default Navbar;
