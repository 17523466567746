import React, { useState } from "react";
import { StaticImage } from 'gatsby-plugin-image';
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

import classes from "./styles/OurServices.module.css";
import Container from "../components/Container/Container";
import ServiceCard from "../components/ServiceCard/ServiceCard";

const OurServices = () => {

  const [cardShowing, setCardShowing] = useState(null);

  const handleChangeCard = (newCard) => {
    setCardShowing(newCard);
  }
  
  return (
    <div className={classes.OurServices}>
      {cardShowing && (
        <div className={classes.serviceCard}>
          <ServiceCard currCard={cardShowing} onChangeCard={handleChangeCard} />
        </div>
      )}

      <div className={classes.navbar}>
        <Navbar />
      </div>

      <div className={classes.content}>
        <Container>
          <h2>Our Services</h2>

          <div className={classes.services}>
            <div className={classes.service} onClick={() => handleChangeCard('Ingrown Toenails')}>
              <StaticImage
                src={'../images/services/ingrownToenail.jpg'}
                alt='Painted toenails'
                placeholder='dominantColor'
              />
              <p>Ingrown Toenails</p>
            </div>

            <div className={classes.service} onClick={() => handleChangeCard('Heel and Arch Pain')}>
              <StaticImage
                src={'../images/services/heelPain.jpg'}
                alt='Feet of cute family'
                placeholder='dominantColor'
              />
              <p>Heel and Arch Pain</p>
            </div>

            <div className={classes.service} onClick={() => handleChangeCard('Verrucae')}>
              <StaticImage
                src={'../images/services/verrucae.jpg'}
                alt='A foot being washed'
                placeholder='dominantColor'
              />
              <p>Verrucae</p>
            </div>

            <div className={classes.service} onClick={() => handleChangeCard('Corns and Calluses')}>
              <StaticImage
                src={'../images/services/cornCallus.jpg'}
                alt='Foot being cleaned'
                placeholder='dominantColor'
              />
              <p>Corns and Calluses</p>
            </div>

            <div className={classes.service} onClick={() => handleChangeCard('Diabetes')}>
              <StaticImage
                src={'../images/services/diabetes.jpg'}
                alt='Runner running up stairs'
                placeholder='dominantColor'
              />
              <p>Diabetes</p>
            </div>

            <div className={classes.service} onClick={() => handleChangeCard('Footwear')}>
              <StaticImage
                src={'../images/services/footwear.jpg'}
                alt='Shoes on display'
                placeholder='dominantColor'
              />
              <p>Footwear</p>
            </div>

          </div>
        </Container>
      </div>

      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  )
}

export default OurServices;