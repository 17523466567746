import React from "react";
import classes from "./footer.module.css";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.sitemap}>
          <h4>Footprint Podiatry</h4>
          <ul className={classes.links}>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/AboutUs">About Us</a>
            </li>
            <li>
              <a href="/OurServices">Our Services</a>
            </li>
            <li>
              <a href="/ContactUs">Contact Us</a>
            </li>
          </ul>
        </div>
        <div className={classes.credits}>
          <p>Site Developed by Justin Kim</p>
          <a href="https://github.com/naznsan">Github</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;