export const menuItems = [
  {
    title: "About Us",
    url: "/AboutUs",
  },
  {
    title: "Our Services",
    url: "/OurServices",
  },
  {
    title: "Resources",
    url: "/Resources",
  },
  {
    title: "Contact Us",
    url: "/ContactUs",
  },
  {
    title: "FAQ",
    url: "/FAQ",
  }
];